<template>
  <div class="guideBox">
    <el-dialog title="办事指南" :visible.sync="visible" width="1000px">
      <div class="bottom" v-loading="loading">
        <table class="table table-bordered table-bd">
          <tbody>
            <tr>
              <td class="bluelabel">
                <label>适用情形</label>
              </td>
              <td colspan="7">{{ baseData.description }}</td>
            </tr>
            <tr>
              <td class="bluelabel">
                <label>承诺时限</label>
              </td>
              <td colspan="3">{{ baseData.cnqx }}</td>
              <td class="bluelabel">
                <label>法定时限</label>
              </td>
              <td colspan="3">{{ baseData.fdqx }}</td>
            </tr>
            <tr>
              <td class="bluelabel">
                <label>咨询电话</label>
              </td>
              <td colspan="3">{{ baseData.zxdh }}</td>
              <td class="bluelabel">
                <label>投诉电话</label>
              </td>
              <td colspan="3">{{ baseData.tsdh }}</td>
            </tr>
            <tr>
              <td class="bluelabel">
                <label>预审时限</label>
              </td>
              <td colspan="7">{{ baseData.yssx }}工作日</td>
            </tr>
            <tr>
              <td class="bluelabel">
                <label>受理条件</label>
              </td>
              <td colspan="7">{{ baseData.sltj }}</td>
            </tr>
            <tr>
              <td class="bluelabel">
                <label>收费标准</label>
              </td>
              <td colspan="7">{{ baseData.sfbz }}</td>
            </tr>
            <tr>
              <td class="bluelabel">
                <label>法定依据</label>
              </td>
              <td colspan="7">{{ baseData.fdyj }}</td>
            </tr>
            <tr>
              <td class="bluelabel">
                <label>申报材料</label>
              </td>
              <td colspan="7" class="istable">
                <table>
                  <thead>
                    <th style="min-width: 100px">材料名称</th>
                    <th style="width: 100px">材料形式</th>
                    <th style="min-width: 200px">材料详细要求</th>
                    <th style="width: 80px">是否必需</th>
                    <th style="width: 50px">示例</th>
                  </thead>
                  <tbody>
                    <tr v-for="item in documentList" :key="item.id">
                      <td>{{ item.clmc }}</td>
                      <td>{{ item.clxs }}</td>
                      <td>{{ item.clsm }}</td>
                      <td>{{ item.sfbx }}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { showGuide, showFiles } from 'iebdc/api/visiting-service';
export default {
  data() {
    return {
      visible: false,
      baseData: {},
      documentList: [],
      loading: true,
    };
  },
  methods: {
    open(data) {
      this.baseData = {};
      this.documentList = [];
      this.visible = true;
      this.guideData(data);
    },
    guideData(data) {
      //点击办事指南
      this.loading = true;
      let param = {
        subcfgId: data.id,
      };
      Promise.all([showGuide(param), showFiles(param)])
        .then((res) => {
          if (res[0].success && res[0].data) {
            this.baseData = res[0].data;
          }
          if (res[1].success && res[1].data) {
            for (const item of res[1].data) {
              if (item.sfbx === true) {
                item.sfbx = '是';
              } else {
                item.sfbx = '否';
              }
            }
            this.documentList = res[1].data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.guideBox /deep/ {
  .el-dialog {
    margin-top: 12%;
  }
  .el-dialog__header {
    padding: 0;
    background: #f5f5f5;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
  }
  .el-dialog__body {
    height: 500px;
    overflow-y: auto;
  }
  .el-dialog__headerbtn {
    top: 16px;
  }
  .el-dialog__title {
    font-weight: bolder;
  }
  .bottom {
    .table {
      line-height: 24px;
      padding-top: 10px;
      border: 1px solid #c9deef;
      border-collapse: collapse;
    }
    .table-bd {
      color: #353535;
      height: 100%;
      tr {
        height: 38px;
        td {
          padding: 8px;
          border: 1px solid #dddddd;
          a {
            cursor: pointer;
          }
        }
      }
      > td {
        width: 25%;
        vertical-align: middle;
      }
      .bluelabel {
        width: 12.5%;
        text-align: center;
        background-color: #eaf1f9;
        vertical-align: middle;
        label {
          margin: 0px;
          font-weight: 700;
        }
      }
      .istable {
        padding: 0px;
        table {
          width: 100%;
          border: none;
          border-collapse: collapse;
          th {
            border-right: 1px solid #dddddd;
            padding: 8px;
            background-color: #eaf1f9;
          }
          td {
            border: none;
            border-right: 1px solid #dddddd;
            border-top: 1px solid #dddddd;
          }
          th:last-child {
            border: none;
          }
        }
      }
    }
  }
}
</style>
